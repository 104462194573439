import React ,{Component} from 'react';
import Parallax from '../components/Parallax.js';
import ReactHtmlParser from 'react-html-parser';

import '../styles/Brief.scss';
import '../styles/tabs.scss';


class BriefExtra extends Component {
  constructor(props){
    super(props);

    this.classes = (this.props.details['classes']) || "";
    this.handleTabClick = this.handleTabClick.bind(this);

    this.state = {
      activeTab : false
    }

  }

  handleTabClick(e){
    let target = e.currentTarget;
    let tabId = target.getAttribute('tab-id');

    if(this.state.activeTab === tabId){
      tabId=false;
    }

    this.setState({
      activeTab: tabId
    })
  }



  render(){
    let content = this.props.content;
    return (
      <article offset="20%" id={this.props.id} key={"article-"+this.props.id} className={this.classes + " brief-extra"}>
        <div className="images-wrapper">
          <Parallax speed={0.10} deltaY={-0} deltaX={1.0} scrollOffset={0}>
            <img src={"../media/2024/01_aret_i_korthet.jpg"} alt=""/>
          </Parallax>
          <Parallax speed={0.10} deltaY={0} deltaX={-1.5} scrollOffset={0}>
            <img src={"../media/2024/02_aret_i_korthet.jpg"} alt=""/>
          </Parallax>
        </div>

        <div className="hotspots">
          {
            Object.keys(content).map((key)=>{
              let tabId = "tab-" + key;
              let classNameHotspot = "hotspot spot-"+key;
              let className = "additional";
              className += tabId === this.state.activeTab ? " active" : "";
              classNameHotspot += tabId === this.state.activeTab ? " active" : "";

              return (
                <section key={"hotspot-key-"+key} className={classNameHotspot}>
                  <div tab-id={tabId} onClick={this.handleTabClick} className="spot-head">
                    <h2 className="title--medium">{ReactHtmlParser(content[key].title)}</h2>
                    <div className="plus"></div>
                  </div>
                  <div className={className} >
                    {ReactHtmlParser(content[key].text)}
                  </div>
                </section>

              )
            })
          }
        </div>

      </article>
    )
  }
}

export default BriefExtra;
