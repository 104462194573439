import React, { useState, useCallback } from 'react';
import Sticky from './Sticky.js';
import Icon from './Icon.js';
import Logo from './Logo.js';
import ReportTextDark from '../img/troax-group-2020.svg';
import { TroaxGroup, DownloadReport } from './TroaxTextSnippets.js';

const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

const Menu = ({ lang, className, year, links, pdfLink }) => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen((prevOpen) => {
      const newState = !prevOpen;
      document.documentElement.classList.toggle('menu-is-open', newState);
      return newState;
    });
  }, []);

  const scrollToId = (id, duration, changeHash = false) => {
    const root = document.scrollingElement || document.documentElement;
    const scrollTo = document.getElementById(id)?.offsetTop - 100;
    const start = root.scrollTop;
    const change = scrollTo - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = () => {
      currentTime += increment;
      root.scrollTop = easeInOutQuad(currentTime, start, change, duration);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      } else if (changeHash) {
        window.location.hash = id;
      }
    };
    animateScroll();
  };

  const handleLinkClick = useCallback((e) => {
    e.preventDefault();
    const articleId = e.currentTarget.getAttribute('href').split('#')[1];
    scrollToId(articleId, 500, false);
    if (articleId === 'banner' && !open) return;
    setTimeout(handleClick, 300);
  }, [open, handleClick]);

  const langCode = lang !== 'en' ? 'en' : 'sv';
  const langLink = lang !== 'en' ? '/en' : './';
  const menuClass = `menu ${open ? 'menu--open' : ''} ${className}`;

  return (
    <Sticky>
      <div className={menuClass}>
        <a href="#banner" onClick={handleLinkClick} className="logo">
          <Logo />
        </a>
        <img className="hidden-mobile annual-as-text" src={ReportTextDark} alt="" />
        <TroaxGroup year={year} className="hidden-mobile" />
        <nav>
          {links.map((textObj) => (
            <a onClick={handleLinkClick} key={textObj.id} href={`#${textObj.id}`}>
              {textObj.title}
            </a>
          ))}
          <div className="hidden-mobile desc">
            <TroaxGroup year={year} />
          </div>
          <div className="hidden-mobile">
            <img src="../media/Troax_Stangsel.jpg" alt="" />
          </div>
        </nav>
        {/* <a href={langLink} className="btn-circle lang-sel">
          <span>{langCode}</span>
        </a> */}
        <DownloadReport year={year} lang={lang} className="hidden-mobile" />
        <a href={`../media/${pdfLink}`} target="download" className="btn-circle pdf">
          <span>PDF</span>
          <Icon name="download-line" size={35} />
        </a>
        <div className="menu-toggle" onClick={handleClick}>
          <Icon className={open ? 'hidden' : ''} name="menu-line" size={35} />
          <Icon className={!open ? 'hidden' : ''} name="close-line" size={35} />
        </div>
      </div>
    </Sticky>
  );
};

export default Menu;