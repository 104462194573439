import React ,{createRef} from 'react';
import ArticleFooter from '../components/ArticleFooter.js';
import ReactHtmlParser from 'react-html-parser';
import '../styles/units.scss';
import UnitsMap from '../UnitsMap/UnitsMap.js';

const Units =(props)=>{


  const classes = props.details['classes'] || "";
  const ref = createRef();

  const removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  const units = props.content.units;
  const regions = removeDuplicates(units.map((unit)=>unit.region));

  const unitsByRegions = regions.reduce((acc, region) => {
  acc[region] = units.filter(unit => unit.region === region);
  return acc;
}, {});
  console.log('regions', regions);
  console.log('unitsByRegions', unitsByRegions);
  

  function handleMoreClick(){
    const el = ref.current;

    el.scrollTo({
      left: el.offsetWidth,
      behavior: "smooth",
    })
  }

  return (
    <article id={props.id} key={"article-"+props.id} className={classes}>
      <div className="inner">

        <header className="header">
            <h1 className="title--medium">{props.content.title}</h1>
        </header>

        <div className="units-wrapper">
          <div className="flex units" ref={ref}>
            {
              Object.keys(unitsByRegions).map((r)=>{
                const regionUnits = unitsByRegions[r];

                console.log('regionUnits', regionUnits);
                

                return (
                  <div className="unit-region" key={r}>
                    <div className="unit-region__header">
                      <div className="unit-region__header__title">
                       {r}
                      </div>
                    </div>

                    <div className="flex flex-nowrap">
                      {
                      Object.keys(regionUnits).map((key)=>{
                        const currentUnit = regionUnits[key];
                        return (
                          <div className="unit" key={currentUnit.city}>
                            <div>
                            <span className="city">{currentUnit.city},</span>
                            <span className="country">{currentUnit.country}</span>
                            <span className="desc">{currentUnit.desc}</span>
                            </div>
                            <div>
                            <ul className="numbers">
                              {
                                Object.keys(currentUnit.numbers).map((number)=>(
                                    <li key={number}>
                                      <span className="highlight-desc">{currentUnit.numbers[number].title}</span>
                                      <span className="highlight">{ReactHtmlParser(currentUnit.numbers[number].number)}</span>
                                    </li>

                                ))
                              }
                            </ul>
                            {currentUnit.text &&
                              <span className="extra">{currentUnit.text}</span>
                            }
                            </div>
                          </div>
                        )})
                      }
                    </div>
                  </div>
                )
                
              })
            }

          </div>
            <div className="units-more" onClick={handleMoreClick}></div>
        </div>

        <div className="production-units-map">
            {/* <img src={"../media/" + props.details.media} alt="Production map"/> */}
            <UnitsMap />
        </div>

    </div>

    <ArticleFooter props={props}/>

  </article>
  )
}


export default Units;
