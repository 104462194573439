import React ,{Component, Fragment} from 'react';
import ArticleFooter from '../components/ArticleFooter.js';
import Parallax from '../components/Parallax.js';
import ReactHtmlParser from 'react-html-parser';

import '../styles/areas.scss'

const AreaBlock = ({title, deltaY=0, deltaX=0, aspectRatio="1/1", number, classes=""})=>(
 <Parallax speed={0.3} deltaY={deltaY} deltaX={deltaX || 0}>
    {/* <img src={"../media/" + image.src} alt={image.alt || "Troax"}/> */}
    <div className={"area-block " + classes} style={{aspectRatio:aspectRatio}}>
      <span className='title--area'>0{number}.</span>
      <h3 className='title--area'>{title}</h3>
    </div>
  </Parallax>
)

class Areas extends Component {
  constructor(props){
    super(props);
    this.classes = (this.props.details['classes']) || "";
  }

  render(){

    const {list, title} = this.props.content;

    const areas = [...list].map((area,i)=>(
      {
        ...area,
        number:i+1,
        // ...( i===1 ? {deltaY:.1} : {deltaY:0}),
        // ...( i===3 ? {deltaY:.2} : {deltaY:0}) 
      }
    ));

    const leftAreas = areas.filter((area,i)=>(area.number%2));
    const rightAreas = areas.filter((area,i)=>!(area.number%2));
    
    return (
      <article id={this.props.id} key={"article-"+this.props.id} className={this.classes}>

        <div className="inner">
          <header className="header">
            <h1 className="title--large">
              {ReactHtmlParser(title)}
            </h1>
          </header>
          <div className="areas">
            <div className="areas--col">
              {leftAreas.map((area,i)=>(
                <Fragment key={`areas-left-${i}`}>
                  <AreaBlock {...area} />
                </Fragment>
              ))}
            </div>
            <div className="areas--col">
              {rightAreas.map((area,i)=>(
                <Fragment key={`areas-right-${i}`}>
                  <AreaBlock {...area} />
                </Fragment>
              ))}
            </div>

          </div>

          <div className="images">
          {
            // areas.map((area,i)=>{
            //   const count = i+1;
            //   return (
            //     <figure className={area.classes} key={"images-"+count}>

            //       <Parallax speed={0.3} deltaY={area.deltaY} deltaX={area.deltaX || 0}>
            //         {/* <img src={"../media/" + image.src} alt={image.alt || "Troax"}/> */}
            //         <div className="area-block" style={{aspectRatio:area.aspectRatio}}>
            //           <h3 className='title--area'>{area.title}</h3>
            //           <span className='title--area'>0{count}.</span>
            //         </div>
            //       </Parallax>

            //     </figure>
            //   )
            // })
          }

          </div>
        </div>
        <ArticleFooter props={this.props}/>

      </article>
    )
  }
}

export default Areas;
