let Diagrams = {};

   Diagrams['profit'] = require('./diagrams/Diagram_sales_per_region_2024_v2.js').default;
   Diagrams['profit_swe'] = require('./diagrams/Diagram_sales_per_region_2024_v2.js').default;
   Diagrams['sales'] = require('./diagrams/Diagram_sales_2024_v2.js').default;
   Diagrams['sales_swe'] = require('./diagrams/Diagram_sales_2024_v2.js').default;
   Diagrams['staple1'] = require('./diagrams/staple1_2024').default;
   Diagrams['staple2'] = require('./diagrams/staple2_2024').default;

   export default Diagrams
