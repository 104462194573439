import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Diagrams from '../Diagrams.js';
import Parallax from './Parallax.js';

const FormatText = (props) => {
  let titles = 0;

  function FormatByType(info){
    let key = info.data;
    let data = props.data[key];
    let type = data.type;
    //let id = props.id || key;
    //let uniqueKey = data.type +"-"+ id+ "-" + key;

    if(type === "diagram"){
      const DiagramToRender = Diagrams[data.content];

      return (
        <div className="diagram" >
          <DiagramToRender lang={data.content.includes('swe') ? "sv" : "en"}/>
        </div>
      )

    }

    else {
      let html = data.content;
      let className = data.classes || "";
      let addTag = false;


      switch (type) {
        case "preamble":
          className += " preamble ";
          addTag = "p";
          break;

        case "quote":
          className += " quote ";
          if(data.author){
            addTag = "div";
            html = "<p>" + data.content + "</p>" +
                  "<div class='author'>" +
                    "<span class='author__name' >" + data.author.name + "</span>" +
                    "<span class='author__title' >" + data.author.title + "</span>" +
                    "<span class='author__corp' >" + data.author.corp + "</span>" +
                  "</div>";
          }
          else {
            addTag = "p";
          }
          break;

        case "image":
          html = "<img class='" + className + "' alt=" + props.data.title + " src='../media/" + data.content + "' />";
          break;

        case "title":
          if(data.heading){
            switch (data.heading) {
              case "1": case "h1":
                className += " title--medium ";
                addTag = "h1";
                break;
              case "2": case "h2":
                className += " title--small ";
                addTag = "h2";
                break;
              case "3": case "h3":
                className += " title--tiny ";
                addTag = "h3";
                break;
              default:
                className += " title--tiny ";
                addTag = "h4";
                break;
            }
          }
          else {
            titles += props.header ? 1 : 0;
            className += titles === 0 ? " title--medium " : " title--small";
            addTag = titles === 0 ? "h1" : "h2";
          }

          break;
        case "text":
          addTag = "p";
          const regex = /[|]/g;
          let splittedText = html.match(regex) ? html.split(regex) : false;
          if(!splittedText)break;
          html = "";
          for (var i = 0; i < splittedText.length; i++) {
            if( splittedText[i].length > 0 && i > 0){
                html += "<br/><span class='indent'></span> " + splittedText[i] ;
            }
            else if (splittedText[i].length > 0 && i === 0){
              html +=  splittedText[i] ;
            }
          }
          break;

        default:
          className += " html-content ";
          addTag = "div";
          break;
      }

      if (addTag && html.length > 0){
        html = html.replaceAll("'", '"');
        html = "<" + addTag + " class='" + className + "' >" + html + "</" + addTag + ">";
      }
      return ReactHtmlParser( html )
    }
  }

  function renderArray(){
    return (
        Object.keys(props.data).map((key)=>{
          if( typeof props.data[key] != 'object' ){
            if(key === "title"){
              return(
                <h1 key={props.id + "-" + key} className="title--medium">{props.data[key]}</h1>
              )
            }
            else {
              return(
                <p key={props.id + "-" + key}>{props.data[key]}</p>
              )
            }
          }
          else {

              if(props.data[key].parallax){
                let values = props.data[key].parallax;
                let speed = values.speed || 0.25;
                let dY = values.dY || -1;
                let dX = values.dX || 0;
                let offset = values.offset || 0;
                return (
                  <Parallax className={props.data[key].classes} key={props.id + "-" + key} speed={speed } deltaY={dY} deltaX={dX} scrollOffset={offset}>
                    <div style={{zIndex:1}}>
                      <FormatByType  data={key}/>
                    </div>
                  </Parallax>
                )
              }
              else {
                return(
                <FormatByType key={props.id + "-" + key} data={key}/>
                )
              }

          }
        })


    );
  }

  let formattedContent;

  if( Array.isArray(props.data)){

    formattedContent = renderArray()
  }
  else {
    return false;
  }


  return (
    formattedContent
  );
}
export default FormatText;
