const observeElements = (selector, callback) => {
  const elements = document.querySelectorAll(selector);
  
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      const rect = entry.target.getBoundingClientRect();
      
      const isAtTop = rect.top <= 0; // Element's top is at the top of the page
      const isTopAtBottom = rect.bottom <= 0; // Element's top is at the top of the page
      const isVisible = entry.isIntersecting; // Element is entering the viewport
      
      if (isVisible || isAtTop) {
        console.log('rect', rect);
        callback(entry.target, isVisible, isAtTop, isTopAtBottom);
      }
    });
  }, { threshold: [0, 1] }); // 0 for enter, 1 for fully visible

  elements.forEach(el => observer.observe(el));
};

function ScrollPoints(animationSelectors) {
    observeElements(animationSelectors, (element, isVisible, isAtTop, isTopAtBottom) => {
      if (isAtTop) {
        console.log(`${element.id || 'Element'} reached the top of the page.`);
        element.classList.add('scroll-point--top');
        const hasDarkTheme = Array.from(element.classList).includes('theme-dark');
        if(hasDarkTheme){
          document.body.classList.add('light-menu');
        }
        else {
          document.body.classList.remove('light-menu');
        }
      }
      if (isTopAtBottom) {
        console.log(`${element.id || 'Element'} reached the bottom of the page.`);
        element.classList.remove('scroll-point--top');
          document.body.classList.remove('light-menu');

      }
      if (isVisible) {
        element.classList.add('scroll-point--active');
        console.log(`${element.id || 'Element'} entered the viewport.`);
      }
      if (!isVisible) {
        element.classList.remove('scroll-point--active');
      }
    });


}

export default ScrollPoints;